import React from 'react';
import { Helmet } from 'react-helmet';
import { siteMetadata } from '@root/gatsby-config';
import ThePackLogo from '@images/the-pack-logo-black.png';

export const SchemaOrganization = () => {
    return (
        <Helmet>
            <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "name": "${siteMetadata.title}",
                    "url": "${siteMetadata.siteUrl}",
                    "logo": "${ThePackLogo}"
                  }
              `}
            </script>
        </Helmet>
    );
};
