import { INPUT_TYPES } from '../../../../lib/constants';

export const sayHi = {
    name: 'Say hi',
    id: 'say_hi',
    action: 'say_hi_form_handler',
    method: 'POST',
    submit_label: 'Send message',
    inputs: {
        full_name: {
            label: 'Full name',
            name: 'full_name',
            type: INPUT_TYPES.TEXT,
            value: '',
            required: true,
            isValid: false,
            column: 1,
            touched: false,
            disabled: false,
            error: '',
        },
        email: {
            label: 'Email',
            name: 'email',
            type: INPUT_TYPES.EMAIL,
            value: '',
            required: true,
            isValid: false,
            column: 1,
            touched: false,
            disabled: false,
            error: '',
        },
        say_hi: {
            label: 'Say hi',
            name: 'say_hi',
            type: INPUT_TYPES.TEXTAREA,
            value: '',
            required: true,
            isValid: false,
            column: 2,
            touched: false,
            disabled: false,
            error: '',
        },
    },
};
