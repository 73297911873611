import React from 'react';

//Styles
import styles from './Message.module.scss';

export const Message = props => {
    const { message, error } = props;

    return (
        <div className={`${styles.message} ${error ? styles.messageError : ''}`}>
            <p>{message}</p>
        </div>
    );
};
