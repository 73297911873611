import React, { useState, useEffect } from 'react';

//Styles
import styles from './Input.module.scss';

//Lib
import { INPUT_TYPES } from '@lib/constants';

//Images
import FileBlack from '@images/icons/file-black.svg';

export const Input = props => {
    const {
        id,
        label,
        name,
        type,
        isValid,
        touched,
        required,
        value,
        error,
        onInputChange,
        accept = '',
        disabled = false,
    } = props;
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if (value.trim() !== '') {
            setFocused(true);
        }
    }, [value]);

    const onInputFocused = () => {
        setFocused(true);
    };

    const onInputBlur = e => {
        if (e.target.value.trim() === '') {
            setFocused(false);
        }
    };

    const className = `${styles.inputContainer} 
      ${
          (focused || value.trim() !== '') && type !== INPUT_TYPES.FILE
              ? styles.inputContainerFocused
              : ''
      }
      ${touched && !isValid ? styles.inputContainerIncorrect : ''}
    `;

    if (
        type === INPUT_TYPES.TEXT ||
        type === INPUT_TYPES.EMAIL ||
        type === INPUT_TYPES.TEL ||
        type === INPUT_TYPES.GEEK_QUESTION
    ) {
        return (
            <div className={`${className} ${styles.inputContainer__text}`}>
                <label htmlFor={id} className={styles.inputContainer__label}>
                    {label}
                    {required ? '*' : ''}
                </label>
                <input
                    className={styles.inputContainer__input}
                    id={id}
                    onFocus={onInputFocused}
                    onBlur={onInputBlur}
                    onChange={e => onInputChange(e, id)}
                    type="text"
                    name={name}
                    value={value}
                    disabled={disabled}
                />

                {error && <div className={styles.inputContainer__errorMessage}>{error}</div>}
            </div>
        );
    }

    if (type === INPUT_TYPES.HIDDEN) {
        return (
            <div className={`${className} ${styles.inputContainer__hidden}`}>
                <input type="hidden" name={name} value={value} />
            </div>
        );
    }

    if (type === INPUT_TYPES.FILE) {
        return (
            <div className={`${className} ${styles.inputContainer__file}`}>
                <label htmlFor={id} className={styles.inputContainer__label}>
                    <FileBlack />
                    {label}
                    {required ? '*' : ''}
                </label>
                <input
                    className={styles.inputContainer__input}
                    id={id}
                    onChange={e => onInputChange(e, id)}
                    type="file"
                    name={name}
                    value={value}
                    disabled={disabled}
                    accept={accept}
                />

                {error && <div className={styles.inputContainer__errorMessage}>{error}</div>}
            </div>
        );
    }

    if (type === INPUT_TYPES.TEXTAREA) {
        return (
            <div className={`${className} ${styles.inputContainer__textarea}`}>
                <label htmlFor={id} className={styles.inputContainer__label}>
                    {label} {required ? '*' : ''}
                </label>
                <textarea
                    id={id}
                    onFocus={onInputFocused}
                    onBlur={onInputBlur}
                    onChange={e => onInputChange(e, id)}
                    name={name}
                    value={value}
                />

                {error && <div className={styles.inputContainer__errorMessage}>{error}</div>}
            </div>
        );
    }

    return null;
};
