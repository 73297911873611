import { INPUT_TYPES } from '../../../../lib/constants';

export const workWithUs = {
    name: 'Work with us',
    id: 'work_with_us',
    action: 'work_with_us_form_handler',
    method: 'POST',
    submit_label: 'Send message',
    inputs: {
        full_name: {
            label: 'Full name',
            name: 'full_name',
            type: INPUT_TYPES.TEXT,
            value: '',
            required: true,
            isValid: false,
            column: 1,
            touched: false,
            disabled: false,
            error: '',
        },
        email: {
            label: 'Email',
            name: 'email',
            type: INPUT_TYPES.EMAIL,
            value: '',
            required: true,
            isValid: false,
            column: 1,
            touched: false,
            disabled: false,
            error: '',
        },
        phone_number: {
            label: 'Phone number',
            name: 'phone_number',
            type: INPUT_TYPES.TEL,
            value: '',
            required: false,
            isValid: true,
            column: 1,
            touched: false,
            disabled: false,
            error: '',
        },
        company: {
            label: 'Company',
            name: 'company',
            type: INPUT_TYPES.TEXT,
            value: '',
            required: false,
            isValid: true,
            column: 1,
            touched: false,
            disabled: false,
            error: '',
        },
        message_content: {
            label: 'Message content',
            name: 'message_content',
            type: INPUT_TYPES.TEXTAREA,
            value: '',
            required: true,
            isValid: false,
            column: 2,
            touched: false,
            disabled: false,
            error: '',
        },
    },
};
