import React from 'react';

//Styles
import styles from './NavigationButton.module.scss';

export const NavigationButton = props => {
    const { label, formId, activeForm, setActiveForm } = props;

    return (
        <button
            className={`${styles.button} ${activeForm === formId ? styles.buttonActive : ''}`}
            aria-label={`Show ${label} form`}
            onClick={() => setActiveForm(formId)}
        >
            {label}
        </button>
    );
};
