import ReCAPTCHA from 'react-google-recaptcha';
import React, { useState, useRef } from 'react';

//Data
import { errorMessages as defaultErrorMessages } from '@data/en/forms/error-messages';

//Styles
import styles from './Form.module.scss';

//Lib
import { INPUT_TYPES } from '@lib/constants';
import { validateEmail } from '@lib/validateEmail';
import { validatePhoneNumber } from '@lib/validatePhoneNumber';
import { MAILAPI_URL } from '@lib/constants';

//Components
import { Input } from '../Input/Input';
import { SubmitButton } from '@components/Buttons/SubmitButton';
import { Message } from '../Message/Message';

export const Form = props => {
    const {
        inputs,
        submitLabel = 'Send',
        method = 'POST',
        action,
        errorMessages = defaultErrorMessages,
        active,
        jobApplication,
    } = props;

    const [message, setMessage] = useState({ body: '', type: null });
    const [formData, setFormData] = useState(inputs);
    const [loading, setLoading] = useState(false);
    const recaptchaRef = useRef(null);

    const inputsArray = [];
    for (const key in formData) {
        inputsArray.push({
            id: formData[key].name,
            inputInfo: formData[key],
        });
    }

    const checkValidity = (value, type, isRequired) => {
        if (!isRequired) {
            return true;
        }

        if (
            type === INPUT_TYPES.TEXT ||
            type === INPUT_TYPES.TEXTAREA ||
            type === INPUT_TYPES.GEEK_QUESTION ||
            type === INPUT_TYPES.FILE
        ) {
            return value.trim() !== '';
        }

        if (type === INPUT_TYPES.EMAIL) {
            return validateEmail(value);
        }

        if (type === INPUT_TYPES.TEL) {
            return validatePhoneNumber(value);
        }
    };

    const onInputChange = (e, id) => {
        const formCopy = { ...formData };
        const formInput = { ...formCopy[id] };

        if (formInput.type !== INPUT_TYPES.FILE) {
            formInput.touched = true;
        } else {
            formInput.label = e.target.files[0].name ? e.target.files[0].name : formInput.label;
        }
        formInput.value = e.target.value;

        if (checkValidity(formInput.value, formInput.type, formInput.required)) {
            formInput.isValid = true;
            formInput.error = '';
        } else {
            formInput.isValid = false;
        }

        formCopy[id] = formInput;
        setFormData(formCopy);
    };

    const onSubmitForm = async e => {
        e.preventDefault();
        e.persist();
        let isError = false;

        const formCopy = { ...formData };

        for (const key in formCopy) {
            if (formData[key].type === INPUT_TYPES.EMAIL && !formData[key].isValid) {
                formData[key].error = errorMessages[INPUT_TYPES.EMAIL];
                isError = true;
            } else if (formData[key].required && formData[key].value.trim() === '') {
                formData[key].error = errorMessages.required;
                isError = true;
            } else if (formData[key].type === INPUT_TYPES.TEL && !formData[key].isValid) {
                formData[key].error = errorMessages[INPUT_TYPES.TEL];
                isError = true;
            } else {
                formData[key].error = '';
            }
        }

        setFormData(formCopy);

        if (isError) return;

        await recaptchaRef.current.executeAsync();

        setLoading(true);

        fetch(`${MAILAPI_URL}?action=${action}`, {
            method: method,
            body: new FormData(e.target),
        })
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                const { message, succes } = data;
                setMessage({
                    body: message,
                    type: succes,
                });
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    };

    const renderInputs = column => {
        return (
            <div className={styles.form__column}>
                {inputsArray.map(input => {
                    const { inputInfo } = input;

                    if (inputInfo.column !== column) {
                        return null;
                    }

                    if (jobApplication && input.id === 'job_name') {
                        inputInfo.value = jobApplication;
                    }

                    return (
                        <Input
                            id={input.id}
                            key={input.id}
                            onInputChange={onInputChange}
                            {...inputInfo}
                        />
                    );
                })}
            </div>
        );
    };

    return active ? (
        <form onSubmit={onSubmitForm} className={styles.form}>
            <ReCAPTCHA
                sitekey="6LcNHWgbAAAAAHDOZLbPd7ApHDKyVPvCbXKU9Qea"
                ref={recaptchaRef}
                size="invisible"
                theme="dark"
            />

            <div className={styles.form__inputWrapper}>
                {renderInputs(1)}
                {renderInputs(2)}
            </div>

            <div className={styles.form__footer}>
                <SubmitButton loading={loading}>{submitLabel}</SubmitButton>
            </div>

            {message.body && <Message message={message.body} error={!message.type} />}
        </form>
    ) : null;
};
