import React from 'react';

//Styles
import styles from './Button.module.scss';

//Images
import HandPointerRightIcon from '@images/icons/hand-pointer-right.svg';
import LoadingBlackIcon from '@images/icons/loading-black.svg';

export const SubmitButton = props => {
    const { children, loading, style } = props;

    return (
        <button
            style={style}
            className={`${styles.button} ${styles.button__tertiary} ${
                loading ? styles.button__tertiaryLoading : ''
            }`}
            type="submit"
        >
            {loading ? <LoadingBlackIcon /> : <HandPointerRightIcon />}

            {children}
        </button>
    );
};
