import React, { useState, useEffect } from 'react';

//Styles
import styles from './ContactForm.module.scss';

//Lib
import { getQueryVariable } from '@lib/getQueryVariable';

//Components
import { Container } from '@components/Utilities/Container/Container';
import { Form } from './Form/Form';
import { NavigationButton } from './NavigationButton/NavigationButton';
import { FadeInWhenVisible } from '@components/Animations/FadeInWhenVisible/FadeInWhenVisible';

export const ContactForm = props => {
    const { forms = [], errorMessages = {}, jumperId, jobApplication } = props;
    const [activeForm, setActiveForm] = useState(forms.length ? forms[0].id : null);

    useEffect(() => {
        const formPreference = getQueryVariable('form');

        if (!formPreference) return;

        const form = forms.find(form => {
            return form.id === formPreference;
        });

        if (!form) return;

        setActiveForm(form.id);
    }, [forms]);

    if (!forms.length) return null;

    return (
        <section id={jumperId} className={styles.contactForm}>
            <Container>
                <FadeInWhenVisible threshold={0.25}>
                    <div className={styles.contactForm__wrapper}>
                        <header className={styles.contactForm__header}>
                            {forms.length === 1 ? (
                                forms[0].title && (
                                    <h2
                                        className="h3"
                                        dangerouslySetInnerHTML={{ __html: forms[0].title }}
                                    ></h2>
                                )
                            ) : (
                                <nav
                                    className={styles.contactForm__navigation}
                                    aria-label="Form navigation"
                                >
                                    <ul className={styles.contactForm__list}>
                                        {forms.map((form, index) => {
                                            return (
                                                <li key={index}>
                                                    <NavigationButton
                                                        label={form.name}
                                                        activeForm={activeForm}
                                                        setActiveForm={setActiveForm}
                                                        formId={form.id}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </nav>
                            )}
                        </header>

                        {forms.map((form, index) => {
                            return (
                                <Form
                                    jobApplication={jobApplication}
                                    key={index}
                                    inputs={form.inputs}
                                    action={form.action}
                                    method={form.method}
                                    submitLabel={form.submit_label}
                                    errorMessages={errorMessages}
                                    active={activeForm === form.id ? true : false}
                                />
                            );
                        })}
                    </div>
                </FadeInWhenVisible>
            </Container>
        </section>
    );
};
