import React from 'react';

//Data
import { data } from '@data/en/pages/contact';
import { formsList } from '@data/en/forms/contact-page';
import { errorMessages } from '@data/en/forms/error-messages';

//Components
import SEO from '@components/Seo/Seo';
import { Header } from '@components/Header/Header';
import { Hero } from '@components/Hero/Hero';
import { ContactForm } from '@components/ContactForm/ContactForm';
import { Footer } from '@components/Footer/Footer';

//Schemas
import { SchemaOrganization } from '@components/Schemas/SchemaOrganization';

const Contact = () => {
    const { seo, path, hero } = data;

    return (
        <>
            <SEO {...seo} path={path} />
            <SchemaOrganization />

            <Header />

            <Hero
                type="contact"
                title={hero.title}
                subTitle={hero.sub_title}
                officeTitle={hero.office_title}
                contactTitle={hero.contact_title}
                small
            />

            <ContactForm
                jumperId={'contact-forms'}
                forms={formsList}
                errorMessages={errorMessages}
            />

            <Footer />
        </>
    );
};

export default Contact;
